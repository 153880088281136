.trans-card{
  width: 262px;
  height: 291px;
  border-radius: 8px;
  background: #fea2aa;
  box-shadow: rgba(203, 127, 132, 0.49) 0 4px 20px;
  margin:  30px auto;
  .b{
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
  }
  .c{
    padding: 27px 13px;
    .x-ipt{
      margin: 0 auto 16px;
      .ipt{
        border-radius: 6px;
      }
    }
  }
  .a{
    align-items: center;
    color: #fff;
    height: 81px;
    display: flex;
    background: #ff818c;
    border-radius: 8px;
    label{
      font-size: 12px;
      padding-bottom: 5px;
      opacity: .7;
    }
    h1{
      font-weight: 200;
      font-size: 24px;
    }
    b{
      margin: 10px;
      border-radius: 8px;
     align-items: center;
      justify-content: center;
      font-size: 30px;
      font-weight: 200;
      display: flex;
      width: 58px;
      height: 58px;
      background: #fea2aa;
    }
  }
}
