.mgPage{
  .art{
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 30px;
    .x-ss{
      overflow: auto;
      flex: 1;
      .rd{
        h1{
          font-weight: 400;
        }
      }
      .r{
        .sd{
          margin: 0 20px;
          color: #999;
        }
       label{
         border-radius: 5px;
         display: block;
         padding: 5px ;
         margin:  0 10px;
         font-size: 10px;
         color: #0a80c4;
         background: #e5f4ff;
       }
        .tg2{
          color: #ff8426;
          background: #fff3ea;
        }
        .tg3{
          color: #008c0a;
          background: #eaffef;
        }
        cursor: pointer;
        &:nth-child(2n-1){
          background: #fafafa;
        }
        &:hover{
          background: #f2f2f2;
        }
        padding: 20px 10px;
        h1{
          font-size: 16px;
        }
        div{
          align-items:  center;
          display: flex;
          flex: 1;
        }
      }
    }
  }
  .ref{
    z-index: 3;
    background: #fff;
    left: 0;
    right: 0;
    top: 20px;
    bottom: 20px;
    position: absolute;
    &>p{
      display: flex;
      justify-content: space-between;
      color: #aaa;
      font-size: 13px;
      text-align: center;
      padding:  10px 30px 0;
    }
    &>span{
      margin-left: 30px;
      cursor: pointer;
      border: 1px solid #ddd;
      background: linear-gradient(#fff,#f2f2f2);
      &:hover{
        background: linear-gradient(#f2f2f2,#fefefe);
      }
      color: #999;
      display: inline-flex;
      width: 60px;
      font-size: 12px;
      align-items: center;
      justify-content: center;
      height: 24px;
      border-radius: 4px;
    }
    flex: 1;
    display: flex;
    flex-direction: column;
    &>h1{
      font-size: 18px;
     text-align: center;
    }
    &>.ctx{
      flex: 1;
      padding: 10px;
      overflow: auto;
      margin: 30px;
    }
  }
 .send-msg{
    margin: 30px auto;
   label{
     width: 56px;
   }
   input,textarea{
     margin: 0;
     &:focus{
       border-color: #377ef6;
     }
     &::placeholder{
       color: #ccc;
     }
     outline: none;
     padding: 12px 22px;
     width: 672px;
     min-height: 50px;
     resize: none ;
     border-radius: 4px;
     border: solid 1px #cccccc;
     background: #f8f8f8;
   }
   textarea{
     margin-top: 6px;
     height: 210px;
   }
   .btn{
     width: 672px;
     height: 50px;
     margin: 42px 63px;
     &.dis{
       background: #aaa;
       box-shadow: #aaa 0 10px 14px -10px;
       pointer-events: none;
     }
   }
 }

}
