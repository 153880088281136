
.p-code.x-select {
  box-shadow: none;
  width: 25px;
  margin: 0;
  border: 0;
  b{
    margin: 0 10px 0 -20px;
    width: 20px;
    height: 14px;
    display: block;
    background:  no-repeat center;
    background-size: 100% auto;
  }
  .f-cn{
    background-image: url("./flags/cn.png");
  }
  .f-hk{
    background-image: url("./flags/hk.png");
  }
  .f-mc{
    background-image: url("./flags/mc.png");
  }
  .f-tw{
    background-image: url("./flags/tw.png");
  }
  .f-ph{
    background-image: url("./flags/ph.png");
  }
  .f-sg{
    background-image: url("./flags/sg.png");
  }
  div {
    padding: 0;
  }

  &:after {
    display: none;
  }

  .opts {
    width: 240px;

    div {
      border-bottom: 1px dashed #eee;
      font-size: 12px;
      padding-left: 20px;
      justify-content: flex-start;
    }
  }
}