.x-st-ti {
  display: flex;
  align-items: center;
  color: #8b8b8b;
  margin-bottom: 10px;
}

.x-sheet {
  flex: 1;
  display: flex;
  flex-direction: column;
  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: #f7f7f7;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f4f4f4;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #bebebe;
  }
  .fu {
    background: url("./img/bk.png");
    width: 230px;
    height: 142px;
    position: relative;
    margin-left: 40px;
    color: #fff;

    i {
      display: block;
      width: 60%;
      height: 52px;
      background: no-repeat left center;
      background-size: contain;
      margin-left: 15px;
    }

    h1 {
      padding-top: 4px;
      font-size: 14px;
      font-weight: 400;
      padding-left: 50px;
    }

    span {
      position: absolute;
      bottom: 34px;
      left: 14px;
    }

    &.o {
      background: url("./img/bk1.png") left top no-repeat;

      &.ali {
        background-image: url("./img/bk3.png");
      }
    }

    &.ali {
      background-image: url("./img/bk2.png");
    }
  }
  .x-dp-q{
    min-width: 108px;
    height: 38px;
  }
  .bn {
    &:hover {
      border: 1px solid #3270e1;
      background: #3270e1;
      color: #ffffff;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    line-height: 25px;
    color: #000000;
    background: #ffffff;
    border: 1px solid #bbbbbb;
    box-sizing: border-box;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s;
  }

  .x-table {
    .isSelf {
      //background: #eef1ff;
    }

    .inf-tip {
      margin-left: 4px;
      font-size: 10px;
      width: 12px;
      height: 12px;
      display: inline-flex;
      border: 1px solid #fff;

      &:hover {
        background: #fff;
        color: #afb5d9;
        z-index: 10;
      }
    }

    .gr {
      color: #37B543;
    }

    .opt {
      max-width: 100px;
      display: inline-flex;
      align-items: center;
      justify-content: space-around;

      .bn {
        padding:  0 10px;
        width: auto;
        font-size: 13px;
        height: 32px;
        color: #333;
        &:hover{
           background: #0a80c4;
          color: #fff;
        }
      }
    }
  }

  .unm {
    &.cl {
      text-decoration: underline;
      cursor: pointer;
    }

    min-width: 100px;
    justify-content: flex-start;
  }

  .isSelf {
    .unm {
      &.cl {
        text-decoration: inherit;
        cursor: auto;
      }
    }
  }

  .i-usr {
    background: url("./img/ur.png") no-repeat center;
  }


  i.i-usr {
    display: block;
    width: 23px;
    height: 20px;
    margin-right: 4px;
  }

  .x-st-h {
    position: relative;
    z-index: 16;

    .btn {
      width: 97px;
      cursor: pointer;
      height: 38px;
      margin-left: 10px;
    }

    .r {
      .i-usr {
        background-position: 3px 60%;
        padding-left: 30px;
      }
    }
  }

  .r {
    padding: 5px 10px;
  }

  .tbs {
    display: flex;

    div {
      display: flex;
      padding: 0 15px;
      height: 45px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: #626262;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 1.05px;

      & + div {
        margin-left: 10px;
      }

      &:hover {
        color: #2a82ff;
      }
    }

    .act {
      border-bottom: 1px solid #6399E9;
      color: #2a82ff;
    }
  }

  .btn {
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    cursor: pointer;
    height: 38px;
    background: #1e5adc;
    color: #fff;
    border-radius: 4px;

    &:hover {
      opacity: .9;
    }
  }

  .x-table {
    margin: 0 auto;

    td {
      border-bottom: 1px solid #f0f0f0;
    }

    tbody {
      tr:hover {
        td {
          background: none;
          position: relative;
          z-index: 100;
        }
      }

      tr:nth-child(2n):not(:hover) {
        td {
          background: #f8f9ff;

          &:first-child {
            border-radius: 6px 0 0 6px;
          }

          &:last-child {
            border-radius: 0 6px 6px 0;
          }
        }
      }
    }

    thead {
      th {
        font-weight: 600;
        font-size: 14px;
        background: #f6f7f8;
        height: 52px;
      }
    }
  }
}

.x-tba {
  padding: 10px;
}

.ft-b {
  border-radius: 0 4px 0 0;
  .x-select{

  }
  & > .r > p {
    color: #bbb;
    position: absolute;
    right: 30px;
    top: 15px;
  }
}

.ft-a {
  background: none;

  .x-sw {
    background: none;

    div {
      background: #faf9fa;
      border: 1px solid #eee;
      border-bottom: 0;
      border-radius: 5px 100px 0 0;
      margin-right: -10px;
    }

    i {
      display: block;
      width: 20px;
      height: 20px;
      margin: 0 4px 0 0;
    }

    .act {
      z-index: 9;
      background: #ffffff;
      color: #000;
      box-shadow: rgba(0, 0, 0, 0.04) 4px -4px 16px -9px;
    }
  }
}

.x-sc {
  .r > input {
    width: 300px;
    outline: none;
    height: 38px;
    border-radius: 3px;
    margin: 0 8px;
    padding: 0 10px;
    border:  1px solid #bbbbbb;
    transition: .2s ease-in-out;

    &:focus {
      box-shadow: inset rgba(9, 131, 255, 0.3) 0 1px 3px, rgba(25, 176, 255, 0.2) 0 1px 5px;
    }

    &::placeholder {
      color: #999;
    }
  }

  .x-select, .x-dates, .sel-ipt {
    font-size: 12px;
    border-radius: 3px;
    position: relative;
    margin-right: 5px !important;
    display: flex;
    align-items: center;
    border: 1px solid #bbb !important;
    padding: 0 5px;
    min-width: 120px;
    height: 38px;
  }
}

.x-tbf {
  padding: 0;

  * {
    white-space: nowrap;
  }

  .pro-footer {
    & > div {
      padding: 0 3px;
    }
  }
}

.x-ft {
  color: #333;
  font-size: 12px;

  b {
    font-weight: 400;
    margin-right: 10px;
    color: #377ef6;
  }
}

.uls {
}

.x-st, .x-tba, .x-ss {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  z-index: 1;
}

.x-sw {
  display: flex;
  width: 170px;
  background: #e1e5ec;
  border-radius: 5px;

  div {
    cursor: pointer;
    height: 42px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: inherit;
  }

  .act {
    background: #2a82ff;
    color: #fff;
  }
}


