.mini-banner {
  opacity: .7;
  transition: .2s ease-in-out;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  overflow: hidden;

  div {
    transition: .3s linear;
    position: absolute;
    left: 0;
    top: 0;
    right: -100%;
    bottom: 0;
    border-radius: 50%;
    border: 9px solid #fff;
    border-left-color: transparent;
    border-bottom-color: transparent;
  }

  &:hover {
    opacity: .94;
  }

  i {
    background: url("./img/next.png");
    width: 70px;
    height: 140px;
    display: block;
  }
}

.carousel {
  .dot {
    position: absolute;
    z-index: 5;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: inline-flex;

    s {
      cursor: pointer;
      display: block;
      width: 18px;
      height: 18px;
      border: 2px solid rgba(255, 255, 255, 0.5);
      border-radius: 10px;
      margin: 0 5px;
      opacity: .5;
      transition: .3s ease-in-out;

      &:hover {
        opacity: 1;
        width: 14px;
      }
    }

    .act {
      opacity: .8;
      border-color: transparent;
      background: rgba(255, 255, 255, 0.5);
    }
  }

  .cs-v {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(71, 138, 254, 0.2);
    overflow: hidden;

    .cs-sc {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;

      &.ani {
        transition: 1s ease-in-out;
      }

      &.n {
        transform: translateX(100%);
      }

      &.p {
        transform: translateX(-100%);
      }

      & > * {
        position: absolute;
        display: block;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background-position: 50% 60%;
        background-size: cover;
      }

      .pre {
        transform: translateX(-100%);
      }

      .cur {
        transform: translateX(0);
      }

      .nxt {
        transform: translateX(100%);
      }
    }
  }

  .cs-d {
    position: absolute;
    right: 10px;
    bottom: 10px;
    height: 20px;
    display: flex;

    i {
      transition: .3s ease-in-out;
      display: block;
      width: 20px;
      height: 20px;
      margin: 0 5px;
      border-radius: 50%;
      border: 2px solid #fff;
      cursor: pointer;

      &.act {
        background: #fff;
      }
    }
  }
}

.carousel-1 {
  margin: 30px 0;
  &:after{
    transform: translate3d(0,0,52px);
    background: transparentize(#0b0b1e,.6);
    content: '';
    pointer-events: none;
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    top: -50px;
    bottom: -50px;
    z-index: 98;
    color: #0b0b1e;
    box-shadow: inset currentColor 0 0 100px ;
  }
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &.ani {
    .bn-tm {
      transition: .3s ease-in-out;
    }
  }

  .c-ps {
    position: relative;
    width: 880px;
    height: 240px;
  }
.c-ctrl{
  z-index: 99;
  margin: 20px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  div{
    opacity: .3;
    background: #0d9fff;
    height: 4px;
    max-width: 50px;
    flex: 1;
    margin: 5px;
    cursor: pointer;
    transition: .3s ease-in-out;
    &.act,&:hover{
      opacity: .8;
    }
  }
}
  .bn-tm{
    perspective-origin: 150% 150%;
    transform-style: preserve-3d;
    perspective:10px;
    box-shadow: rgba(0,0,0,.25) 0 0 20px;
  }
  .bn-tm,a {
    border-radius: 5px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
  }
  a{
    background-size: cover;
  }
}
