.rate-cfg{
  flex: 1;
  .de {
    white-space: nowrap;
    display: flex;
    width: 235px;
    height: inherit;
    align-items: center;

    span {
      text-align: right;
      position: relative;
      font-weight: bold;
      margin-right: 20px;
    }

    span:last-child {
      margin-right: 34px;

      &:before {
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 50%;
        display: block;
        background: #5c96e6;
        position: absolute;
        right: -27px;
        top: 6px;
      }
    }

    span:first-child {
      width: 50px;

      &:after {
        content: '%';
        font-size: 10px;
        position: absolute;
        right: -15px;
        top: -5px;
      }
    }
  }
  .mo {
    display: flex;
    align-items: center;
    height: 34px;
    border-radius: 0 0 4px 4px;
    background: #e1e5eb;
    margin-top: -10px;
    padding: 0 40px;

    label {
      width: 30px;
    }

    div {
      label {
        color: #999;
        padding-right: 10px;
      }
    }

    input {
      border: 0;
      box-shadow: none;
      outline: none;
      padding: 0 10px;
      height: 25px;
      width: 244px;
      border-radius: 4px;
    }
  }
  .rc-slider {
    height: 6px;
    padding: 0;
  }
  .rc-slider-track:after {
    content: '';
    position: absolute;
    display: block;
    top: -18px;
    bottom: -18px;
    left: 0;
    right: 0;
    color: inherit;
    background: linear-gradient(90deg, transparent, currentcolor);
  }

  i {
    background: currentColor center no-repeat;
  }

  .r0 i {
    background-image: url("./img/i2.svg");
  }

  .r1 i {
    background-image: url("./img/i3.svg");
  }

  .r2 i {
    background-image: url("./img/i4.svg");
  }

  .r3 i {
    background-image: url("./img/i5.svg");
  }

  .r4 i {
    background-image: url("./img/i6.svg");
  }

  .r5 {
    i {
      background-image: url("./img/i7.svg");
    }
    .de{
      span:last-child{
        margin-right: 20px;
        &:before{
          right:-13px
        }
      }
    }
  }

  label {
    margin: 0 5px;
    width: 70px;
    display: flex;
    font-size: 12px;
    flex-direction: column;
    span {
      position: relative;
      color: #6C6C6C;
      white-space: nowrap;
      font-size: 10px;
      font-weight: 290;
      line-height: 13px;
      text-transform: uppercase;
      letter-spacing: 0.75px;
    }
  }

  .sc {
    position: relative;
    z-index: 1;
    flex: 1;
    display: flex;
    align-items: center;

    i {
      position: relative;
      z-index: 3;
      margin-right: -12px;
      width: 24px;
      height: 24px;
      display: block;
      border-radius: 50%;
      pointer-events: none;
      box-shadow: currentColor 0  4px 18px -10px;
      &:before{
        opacity: .2;
        content: '';
        display: block;
        left: -3px;
        right: -3px;
        bottom: -3px;
        top: -3px;
        border-radius: 50%;
        border: 3px solid currentColor;
        position: absolute;
      }
    }
  }

  .r {
    height: 40px;
    background: #fff;
    .rc-slider-handle{
      margin-top: -8px;
    }
    &.lg{
      height: 50px;
      .rc-slider-handle{
        margin-top: -5px;
      }
      .rc-slider{
        height: 12px;
      }
      .rc-slider-track ,.rc-slider-rail{
        min-height: 12px;
      }
      .rc-slider-track:after{
        top: -20px;
        bottom: -20px;
      }
    }
    &+.r{
      margin-top: 12px;
    }
    & > i {
      width: 34px;
      height: 34px;
      border-radius: 0 04px 4px 0;
      margin-right: 5px;
    }
  }
}
