.err-404-page{
  min-height: 100%;
  display: flex;
  flex-direction: column;
  .logo-head{
    box-shadow: rgba(0,0,0,.1) 0 3px 10px -2px;
  }
  .lg-bg{
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .no-page{
    flex: 1;
    flex-direction: column;
    margin:  30px auto;
    color: #00a0fe;
  }
  .no-page{
   display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
  h1{
    margin-bottom: 30px;
  }
}
