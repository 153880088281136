.pro-footer{
  display: flex;
  align-items: center;
  .prz-num{
    background: none;
  }
  .mr-extra{
    top: auto;
    bottom: 30px;
    &:after{
      top: auto;
      bottom: -6px;
      transform: translateX(-50%) rotateZ(-135deg);
    }
  }
  .mr-data{
    background: none;
  }
  &>div{
    label{
      opacity: .5;
      padding-right: 3px;
    }
    display: flex;
    align-items: center;
    padding:  0 10px;
  }
}
