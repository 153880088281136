.welfarePage {
  .x-st-ti {
    display: none;
  }

  .x-sc {
    position: absolute;
    top: 12px;
    left: 90px;
    right: 0;

    .r {
      height: 50px;
      align-items: center;
      justify-content: space-between;
      padding: 10px 30px;

      p {
        height: 24px;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
        color: #133364;
        background: #eef0f8;
        display: inline-flex;
        margin: 0 27px;
        padding: 0 18px;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 116px;
        height: 40px;
        border-radius: 6px;
        border: solid 1px #dae4f1;
        background: #ffffff;
        color: #3270e1;
      }
    }
  }
}
