
.pop-wallet {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;

  .x-sheet {
    .btn {
      height: 40px;
      box-shadow: rgba(90, 145, 240, 0.5) 0 8px 20px -6px;
    }

    .x-st-h {
      z-index: 3;
      position: relative;
      padding-top: 20px;
      padding-bottom: 0;
    }

    .x-st-h, .x-tba {
      box-shadow: none;
      margin: 0;
    }
  }

  .ti {
    z-index: 11;
    display: flex;
    height: 50px;
    background: #22368e  center no-repeat;
    color: #e2e3ec;
    align-items: center;
    font-size: 13px;

    p {
      margin-left: 20px;

      span {
        padding: 0 2px;
        font-size: 16px;
        letter-spacing: 1px;
      }
    }

    div {
      height: inherit;
      cursor: pointer;
      min-width: 300px;
      padding: 0 20px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: relative;
      &:not(.act):hover {
        background: rgba(255, 255, 255, 0.2);
      }
    }

    .i-0 {
      background-position: -32px 0;
    }

    .i-1 {
      background-position: 0 0;
    }

    .i-2 {
      background-position: -62px 0;
    }

    .act {
      border-radius: 6px 6px 0 0;
      pointer-events: none;
      background: #fff;
      color: #102754;

      .i-0 {
        background-position: -32px 26px;
      }

      .i-1 {
        background-position: 0 26px;
      }

      .i-2 {
        background-position: -62px 26px;
      }
    }
  }
}
