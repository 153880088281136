.pop-notice-mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.24);
  z-index: 0;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
  &.act {
    opacity: 1;
    z-index: 10;
    transition: .3s ease-in-out;
  }
  .ct {
    font-size: 13px;
    color: #ddd;
    text-align: right;
    padding: 0 30px 10px;
  }
}

.pop-ctx {
  flex: 1;
  position: relative;
  overflow: auto;
  padding: 10px 30px 5px;
  font-size: 14px;
  max-height: 250px;
}

.pop-hd {
  border-radius: 10px 10px 0 0;
  background: #000;
  height: 121px;
  display: flex;
  overflow: visible;
  justify-content: center;
  align-items: center;
  position: relative;
  &:before{
    content: '';
    position: absolute;
    left: -1px;
    right: -1px;
    bottom: 0;
    top: -40px;
    background: url("../../assets/images/hh.png") right bottom no-repeat;
    background-size: 100% auto;
  }
  span {
    position: relative;
    z-index: 1;
    width: 90%;
    display: block;
    text-align: center;
    font-size: 18px;
    color: #c2b298;
    background: -webkit-linear-gradient(#ffeeb8, #796e71);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.pop-ft {
  position: relative;
  height: 80px;
  z-index: 1;
  .no-today {
    position: absolute;
    right: 20px;
    bottom: 15px;
    display: flex;
    font-size: 14px;
    align-items: center;
    cursor: pointer;
    user-select: none;
    i {
      margin-right: 8px;
      width: 17px;
      height: 17px;
      border-radius: 3px;
      border: 1px solid #666;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
      color: #000;
      &.act {
        &:before {
          left: -1px;
          position: relative;
          content: '√';
        }
      }
    }
  }

  .more {
    width: 300px;
    position: relative;
    top: 10px;
    left: 30px;
    height: 60px;
    font-size: 14px;
    line-height: 2;
    overflow: hidden;

    div {
      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 6px;
        height: 6px;
        bottom: 10px;
        border-radius: 50%;
        left: 0;
        background: orange;
      }

      cursor: pointer;

      &:hover {
        p {
          text-decoration: underline;
        }
      }

      transition: .3s ease-in-out;
      position: relative;
      height: 30px;
      overflow: hidden;

      &.h {
        height: 0;
      }

      p {
        position: absolute;
        overflow: hidden;
        left: 15px;
        right: 0;
        bottom: 0;
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

.pop-close {
  position: absolute;
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  right: -36px;
  top: -36px;

  &:after {
    content: 'X';
  }

  opacity: .8;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  border: 1px solid #fff;
  box-shadow: rgba(0, 0, 0, .15) 0 0 5px;
  background: #100f14;
  color: #fff;
  border-radius: 50%;
}

.pop-n-box {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(153, 153, 153, 0.33);
  border-radius: 10px;
  position: relative;
  box-shadow: rgba(0, 0, 0, .15) 0 10px 30px;
  width: 600px;
  min-height: 400px;
  background: #fff;
  &:before{
    content: '';
    position: absolute;
    left: -38px;
    bottom: -53px;
    right: -11px;
     background: url("../../assets/images/ff.png") center bottom no-repeat;
    background-size: 100% auto;
    height: 312px;
  }
}
