.foundDetailPage{
  .x-select{
    width: 130px;
    margin-right: 20px;
  }
  .x-sc{
    .r{
      padding:  0 14px 10px;
    }
  }
}
