@import '~breakpoint-sass/stylesheets/breakpoint';

.nv-bt {
  background-image: url("./img/gs/lbt.png");
  background-position: 75% 50%;
}

.nv-tcg {
  background-image: url("./img/gs/tcg.png");
  background-position: 40% 50%;
}

.nv-27 {
  background-image: url("./img/gs/pt.png");
}

.nv-24 {
  background-image: url("./img/gs/pt.png");
}

.nv-26 {
  background-image: url("./img/gs/pt.png");
}

.nv-1 {
  background-image: url("./img/gs/pt.png");
}

.nv-2 {
  background-image: url("./img/gs/ag.png");
}

.nv-3 {
  background-image: url("./img/gs/mg.png");
}

.nv-4 {
  background-image: url("./img/gs/gg.png");
}

.nv-5 {
  background-image: url("./img/gs/bbin.png");
}

.nv-6 {
  background-image: url("./img/gs/ttg.png");
}

.nv-29 {
  background-image: url("./img/gs/pg.png");
}

.nv-7 {
  background-image: url("./img/gs/ea.png");
}

.nv-8 {
  background-image: url("./img/gs/png.png");
}

.nv-25 {
  background-image: url("./img/gs/jdb.png");
}

.nv-9 {
  background-image: url("./img/gs/haba.png");
}

.nv-10 {
  background-image: url("./img/gs/isb.png");
}

.nv-11 {
  background-image: url("./img/gs/cq9.png");
}

.nv-12 {
  background-image: url("./img/gs/ab.png");
}

.nv-13 {
  background-image: url("./img/gs/lt.png");
}

.nv-14 {
  background-image: url("./img/gs/ibc.png");
}

.nv-15 {
  background-image: url("./img/gs/ky.png");
}

.nv-16 {
  background-image: url("./img/gs/av.png");
}

.nv-17 {
  background-image: url("./img/gs/sexy.png");
}

.nv-18 {
  background-image: url(./img/gs/alb.png);
}

.nv-19, .nv-30 {
  background-image: url(./img/gs/bt.png);
}

.nv-20 {
  background-image: url(./img/gs/eg.png);
}

.nv-22 {
  background-image: url(./img/gs/yb.png);
}

.nv-28 {
  background-image: url(./img/gs/im.png);
}

.nv-wt {
  background-image: url(./img/gs/mor.png);
}

.prz-num {
  height: 24px !important;
  padding: 0 10px;
  align-items: center;
  margin: 0 auto;
  border-radius: 5px;
  background: #FCE8E8;
  color: #A25B59;
  display: inline-flex !important;

  &.a {
    background: #ECEDF2;
    color: #333;
  }

  &.b {
    color: #548775;
    background: #E2F7EF;
  }

  &.s {
    color: #FA7050;
    background: none;

    &.b {
      color: #37B543;
    }
  }
}

.tp-txt {
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;

  & > span {
    line-height: 26px;
    display: inline-block;
    max-width: 300px;
  }
}

.op-lk {
  font-size: 12px;
  color: #2B81FF;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;

  i {
    background: url("./img/o.svg") center no-repeat;
    display: block;
    width: 13px;
    height: 13px;
    margin-right: 3px;
  }
}

.tp-done, .tp-fail {
  display: inline-block;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  margin-right: 5px;
  background: #CFFAC1 url("./img/d.svg") center no-repeat;
}

.tp-fail {
  background: #FAD6C1 url("./img/x.svg") 6px 5px no-repeat;
}

.tt-a:not(.x-loading ) {
  display: flex;
  align-items: center;
  margin: 10px 0;
  width: 100%;

  i {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-size: 60% !important;
    margin-right: 5px;
  }

  .usr {
    background: #2cc065 url("../../assets/images/bnk/usr.svg") no-repeat center;
  }

  .lk {
    background: #498fe0 url("../../assets/images/bnk/lock.svg") no-repeat center;
  }

  .bk {
    background: #1a9ce3 url("../../assets/images/bnk/mail.svg") no-repeat center;
  }

  i.mo {
    background: url("../../assets/images/bnk/mo.png") no-repeat center;
    background-size: cover !important;
  }
}

.dash-title {
  display: flex;
  align-items: center;

  p {
    position: relative;
    color: #eee;
    font-size: 16px;
    left: 4px;
    top: 9px;
  }

  h1 {
    position: relative;
    padding: 0 20px;
    margin: 0 0 20px 0;
    height: 35px;
    background: rgb(43, 68, 177);
    border-radius: 10px 20px 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    color: #fff;
  }

  span {
    position: relative;
    top: 4px;
    left: 5px;
    color: #999;
  }

  i {
    display: block;
    margin-right: 5px;
  }

  .i-vip {
    background: url("../../assets/images/new/iv.png");
    width: 23px;
    height: 23px;
  }

}

.wal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 40px;
  padding: 0 5px 0 10px;

  i {
    &:before {
      content: none !important;
    }
  }

  b {
    color: #a2d5fa;
    font-weight: normal;
    flex: 1;
    text-align: right;
    padding-right: 10px;
  }

  .ld {
    margin-left: 10px;
  }

  .sub-wal {
    pointer-events: none;
    opacity: 0;
    transition: .2s ease-in-out;
    position: absolute;
    top: 39px;
    background: linear-gradient(90deg, #abc2ff, #57acff);
    left: 0;
    border: 1px solid #689af8;
    width: 206px;
    box-shadow: rgba(0, 0, 0, .2) 0 4px 20px -6px;

    .t {
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;

      i {
        width: 15px;
        height: 15px;
        border: 1px solid currentColor;
        font-style: normal;
        font-size: 12px;
        border-radius: 50%;
        padding: 0;
        transform: none;
        margin-right: 5px;

        &:before {
          content: none;
        }
      }
    }

    & > div, p {
      display: block;
      height: 41px;
      text-align: center;
      line-height: 41px;
      font-size: 12px;
      color: #fff;
    }

    .r {
      border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
    }

    .r, p {
      display: flex;
      align-items: center;
      justify-content: center;

      label {
        width: 35%;
        text-align: left;
      }

      span {
        text-align: right;
        width: 35%;
      }
    }

    .btn {
      border-radius: 3px 3px 0 0;
      margin: 0 auto;
      width: 183px;
      height: 38px;
      cursor: pointer;
      transition: .2s ease-in-out;
      line-height: 38px;
      background: rgba(255, 255, 255, 0.6);

      & + .r {
        border-top: 1px solid rgba(255, 255, 255, 0.5);
      }

      color: #377ef6;

      &:hover {
        background: rgba(255, 255, 255, 0.8);
      }
    }
  }

  &:hover {
    background: rgba(0, 0, 0, 0.2);

    .sub-wal {
      opacity: 1;
      pointer-events: all;
    }
  }
}

.wal, .sport-page {
  .ld {
    &:before {
      display: none;
    }

    z-index: 6;
    transform: none;
    background: url("./l.svg") center no-repeat;
    width: 16px !important;
    height: 16px !important;
    background-size: 80% auto;

    &.act {
      pointer-events: none;
      animation: .5s rotateZ360 both linear infinite;
    }
  }
}

.n-notice-ls {
  a {
    display: block;
    height: 30px;
    line-height: 30px;
    color: #d4d4d4;
    font-size: 12px;
    padding: 0 10px;
  }
}

.new-hd {
  position: fixed;
  box-shadow: rgba(0, 0, 0, .25) 0 4px 20px;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  height: 60px;
  background: #2b44b1;

  & + div {
    margin-top: 60px;
  }
}

.nh-ex {
  flex: 1;
}

.nh-lo {
  width: 170px;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    display: block;
    width: 100%;
    height: 100%;
    background: url(./img/lo.png) 50% 20% no-repeat;
    background-size: auto 85%;
  }
}

.nhd0 {
  display: flex;
  position: relative;
  z-index: 23;
  color: #d4d4d4;
  background: #0b1228;
  font-size: 13px;

  .srv {
    white-space: nowrap;
    cursor: pointer;
    height: 40px;
    margin-left: 5px;
    border-left: 1px solid #555;
    border-right: 1px solid #555;
    padding: 0 10px;

    &:hover {
      background: #000;

      & > * {
        opacity: .8;
      }
    }

    & > * {
      opacity: .6;
    }

    s {
      background: url("./img/srv.svg") no-repeat center;
      background-size: 100% auto;
      width: 18px;
      height: 18px;
      display: block;
      margin-right: 3px;
    }
  }

  .container {
    padding: 0 20px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  i {
    display: block;
  }
}

a.ru {
  margin-right: 20px;
}

.n-notice-ls {
  flex: 1;
}

.nhd-n {
  flex: 1;
  padding-left: 20px;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid #273151;
  font-size: 14px;
  display: flex;

  a {
    color: #fff;
  }

  i {
    opacity: .7;
    background: url("../../assets/images/new/0.svg");
    width: 20px;
    height: 19px;
  }
}

.nhd-m {
  padding: 0 10px 0 20px;

  .reg, .lg {
    border-radius: 2px;
    width: 84px;
    color: #fff;
    margin: 0 11px 0 5px;
    position: relative;
    font-size: 12px;
    opacity: .9;
    background: #22283c;
    height: 40px;
    justify-content: center;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  .lg {
    background-color: #1e5adc;
  }

  .ipt {
    margin-right: 5px;
    height: 28px;
    background: #fff;
    border-radius: 3px;
    line-height: 26px;
    box-shadow: inset rgba(0, 0, 0, .2) 0 0 5px;
    box-sizing: border-box;
    display: flex;

    & + span:hover {
      background: linear-gradient(90deg, #61a7f8, #3079f5);
      color: #fff;
    }

    &.focus {
      box-shadow: #377ef6 0 0 2px 2px;

      & + span {
        background: linear-gradient(90deg, #61a7f8, #3079f5);
        color: #fff;
      }
    }

    input {
      outline: none;

      &:focus {
        box-shadow: inset #0d9fff 0 0 4px;
      }
    }

    &.wb {
      input {
        width: 120px;
      }
    }

    &.ca {
      input {
        width: 70px;
      }

      .captcha {
        width: 70px;
        height: 28px;
      }
    }

    input {
      width: 142px;
      height: 28px;
      border: none;
      margin: 0;
      padding: 0 10px;
      background: none;
    }

    input:focus + span, span:hover {
      background: linear-gradient(90deg, #61a7f8, #3079f5);
      color: #fff;
    }

    & + span {
      text-align: center;
      background: #fff;
      cursor: pointer;
      color: #000;
      width: 60px;
      display: flex;
      margin-left: -6px;
      align-items: center;
      justify-content: center;
      border-radius: 0 3px 3px 0;
      z-index: 1;
      opacity: 1;
      box-shadow: rgba(0, 0, 0, .1) -2px 0 3px;
      height: 28px;
    }

    .ld {
      pointer-events: none;
      color: #666;
      background: #eee;
    }
  }

  .m, i, .btn a {
    opacity: .9;
    transition: .1s ease-in-out;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  display: flex;
  align-items: center;

  & > a,
  & > a:hover,
  & > div {
    display: flex;
    color: #fff;
    align-items: center;
  }

  i {
    &:before {
      content: '^';
      color: #fff;
      font-style: normal;
    }

    transform: rotateZ(180deg) scaleY(.6);
    display: block;
    width: 16px;
    height: 17px;
    margin-left: 5px;
    opacity: .85;
  }

  .btn {
    color: #fff;
    text-align: center;

    a {
      margin: 0 4px;
      width: 80px;
      height: 30px;
      text-align: center;
      font-size: 13px;
      color: #fff;
      opacity: .9;
      line-height: 30px;
      border-radius: 3px;
    }

    .c0 {
      background: #22283c;
    }

    .c1 {
      background: #1e5adc;
    }

    .c2 {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      margin-left: 5px;
      color: #777;
      line-height: 22px;
      height: 28px;
      cursor: pointer;
      border-radius: 3px;
      padding: 0 10px;
    }
  }
}

.nhd1 {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 20;
  height: 60px;
  padding: 0 50px 0 10px;

  .c1 {
    &:hover {
      background: linear-gradient(180deg, #ffe7c6 0%, #ffc392 100%);
    }

    cursor: pointer;
    text-align: center;
    font-size: 14px;
    padding: 7px 0;
    height: 36px;
    margin-left: 10px;
    box-sizing: border-box;
    width: 70px;
    border-radius: 10px;
    color: #883d00;
    background: linear-gradient(180deg, #ffe2b5 0%, #ffb77c 100%);
  }

  a {
    color: #fff;
  }

  .mu {
    display: flex;

    i {
      background: url("../../assets/images/new/m0.svg") no-repeat center;
      background-size: 61px 374px;
      display: inline-block;
      width: 28px;
      height: 28px;
      position: relative;
      margin-right: 5px;
      zoom: .8;
      opacity: .8;
      top: 2px;
    }

    .i-fa {
      background-position: -35px -291px;
    }

    .i-v {
      background-position: -35px 5px;
    }

    .i-wa {
      background-position: -35px -210px;
    }

    .i-c {
      background-position: -35px -351px;
    }

    .i-h1 {
      background-position: -35px -83px;
    }

    .i-h2 {
      background-position: -35px -54px;
    }

    .i-h3 {
      background-position: -35px -179px;
    }

    .i-wi {
      background-position: -35px -319px;
    }

    .i-c1 {
      background-position: -35px -351px;
    }

    .i-h {
      background-position: -35px -114px;
    }

    .i-th {
      background-position: -35px -150px;
    }

    .i-sc {
      background: url("./img/sc.svg") center no-repeat;
      opacity: .7;
      background-size: 90% auto;
    }

    .i-pz {
      background: url("./img/pr.svg") center no-repeat;
      opacity: .7;
      background-size: 90% auto;
    }

    .i-m {
      background-position: -35px -264px;
    }

    .i-g1 {
      background-position: -35px -27px;
    }
  }

  .mu_span {
    transition: .3s ease-in-out;
    justify-content: center;
    display: flex;
    height: 60px;
    padding: 0 20px;
    align-items: center;
    color: rgb(187, 187, 187);

    &:hover {
      background: rgb(34, 54, 142);
    }

    &.act {
      i {
        opacity: 1;
      }

      color: #fff;
    }
  }

  .hb0 {
    flex: 1;
    justify-content: flex-end;
    display: flex;
    align-items: center;

    * {
      color: #fff;
    }

    .usr-sub {
      border-radius: 7px;
      opacity: 0;
      pointer-events: none;
      width: 240px;
      background-size: cover;
      position: absolute;
      top: 55px;
      left: 5px;
      border: 1px solid #eee;
      box-shadow: rgba(0, 0, 0, 0.2) 0 4px 15px -3px;
      background: #fff;
      transition: .3s ease-in-out;
      div{
        font-size: 13px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 38px;
        color: #16549a;
        &:hover{
          background: #f4f4f4;
        }
      }
     a{
       border-bottom: 1px solid #eee;
       height: 38px;
       padding:  0 20px 0 10px;
       display: flex;
       align-items: center;
       color: #999;
       &:hover{
         background: #f2f2f2;
       }
       i{
         background: url("../../assets/images/new/m1.svg") no-repeat center;
         background-size: 61px 374px;
         display: inline-block;
         width: 28px;
         height: 28px;
         position: relative;
         margin: 0 10px;
         opacity: .5;
         zoom: .8;
         top: 3px;
       }
       .i-m {
         background-position: -35px -264px;
       }
       .i-wa {
         background-position: -35px -210px;
       }
       .i-sc{
         background: url("./img/sc1.svg") center no-repeat;
         background-size: 90% auto;
       }
       .i-pz {
         background: url("./img/pr.svg") center no-repeat;
         background-size: 90% auto;
       }
       span:not(.unread){
         font-size: 13px;
         display: flex;
         align-items: center;
         flex: 1;
         color: #666;
       }
       s{
         opacity: .4;
         display: block;
         width: 8px;
         height: 8px;
         border: 1px solid #000;
         border-left: 0;
         border-bottom: 0;
         transform: rotate(45deg);
       }
     }
      &:before {
        position: absolute;
        top: -20px;
        height: 20px;
        content: '';
        display: block;
        right: 0;
        left: 0;
      }
    }

    .u {
      display: flex;
      align-items: center;
      height: 40px;
      position: relative;

      s {
        cursor: pointer;
        width: 40px;
        height: 40px;
        background: center no-repeat;
        display: block;
        border-radius: 50px;
        background-size: 100% 100%;
      }

      .v {
        height: 50px;
        display: flex;
        align-items: center;
        position: relative;
        margin: 0 10px;
        justify-content: center;
        span {
          border-radius: 10px;
          background: transparentize(#fff,.3);
          font-size: 10px;
          padding: 2px 5px;
          color: grey;
          margin-right: 5px;
        }
      }

      .usr-sub:hover,
      &:hover .usr-sub {
        display: block;
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  .logo {
    background: url("../../assets/images/new/19.svg");
    width: 278px;
    height: 52px;
    cursor: pointer;
  }


  @keyframes shakeY {
    0% {
      transform: translateX(0);
    }

    6.5% {
      transform: translateX(-6px) rotateY(-9deg);
    }

    18.5% {
      transform: translateX(5px) rotateY(7deg);
    }

    31.5% {
      transform: translateX(-3px) rotateY(-5deg);
    }

    43.5% {
      transform: translateX(2px) rotateY(3deg);
    }

    50% {
      transform: translateX(0);
    }
  }

  .nv-su {
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 100%;
    padding-top: 8%;
    min-height: 100px;
    box-sizing: border-box;
    padding-left: 15%;
    display: inline-block;

    &:hover {
      animation: shakeY 1s ease-in-out;
    }
  }

}

.nft0 {
  height: 60px;
  text-align: center;
  background: #0d1121;
  color: #7b8091;
  line-height: 40px;
  font-size: 13px;
}

.ft1 {
  i {
    display: block;
    background-size: 100% auto !important;
  }

  .i0 {
    background: url("./img/o6.png");
    width: (163px / 2);
    height: (35px / 2);
  }

  .i1 {
    background: url("./img/o0.png");
    width: (112px / 2);
    height: (37px / 2);
  }

  .i2 {
    width: (96px / 2);
    height: (34px / 2);
    background: url("./img/o4.png");
  }

  .i3 {
    background: url("./img/o1.png");
    width: (116px / 2);
    height: (37px / 2);
  }

  .i4 {
    width: (60px / 2);
    height: (38px / 2);
    background: url("./img/o2.png");
  }

  .i5 {
    width: (145px / 2);
    height: (35px / 2);
    background: url("./img/o3.png");
  }

  .i6 {
    width: (138px / 2);
    height: (28px / 2);
    background: url("./img/o5.png");
  }

  .i7 {
    background: url("./img/u3.png");
    width: (179px / 2);
  }

  .i8 {
    width: (310px / 2);
    background: url("./img/u2.png");
  }

  .i9 {
    width: (339px / 2);
    background: url("./img/u1.png");
  }

  .ia {
    width: (83px / 2);
    background: url("./img/u0.png");
  }

  background: #000;
  height: 219px;

  s {
    display: block;
    height: 19px;
    border-left: 1px solid #222;
  }
}

.ff11 {
  padding: 55px 0 0 0;
}

.ft0 {
  display: flex;
  width: 740px;
  margin: 0 auto;
  align-items: center;
  justify-content: space-around;
}

.ft2 {
  margin: 25px auto;
  width: 890px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  i {
    height: 50px;
    background-position: center !important;
    background-repeat: no-repeat !important;
  }

  div {
    display: flex;
    flex-direction: column;
    font-size: 13px;
    align-items: center;
    justify-content: center;
    color: #878ea6;
    letter-spacing: 1px;
  }
}

.game-cate {
  display: flex;
  border-radius: 20px;
  height: 110px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 14px -3px;
  margin-top: 20px;
  position: relative;
  overflow: hidden;

  .arr {
    opacity: .8;
    width: 20px;
    position: absolute;
    top: 0;
    bottom: 0;
    background: #fff;
    z-index: 3;
    transition: .1s ease-in-out;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ddd;

    &:hover {
      opacity: 1;
      color: #000;
    }

    &:after {
      position: relative;
      content: '';
      display: block;
      height: 0;
      width: 0;
      border: 7px solid transparent;
    }

    &.hide {
      opacity: 0;
      pointer-events: none;
    }

    &.l {
      left: 0;

      &:after {
        left: -5px;
        border-right-color: currentColor;
      }
    }

    &.r {
      right: 0;

      &:after {
        right: -5px;
        border-left-color: currentColor;
      }
    }
  }

  [class^='i-'] {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;

    &.act, &:hover {
      background: linear-gradient(transparent 60%, #f7f7f7);
      font-weight: 600;

      i {
        filter: grayscale(0);
      }
    }

    & + div {
      border-left: 1px solid rgba(0, 0, 0, 0.1);
    }

    span {
      font-size: 13px;
      margin-top: 5px;
    }

    i {
      display: block;
      width: 44px;
      height: 44px;
      background: no-repeat;
      transform: translate3d(0, 0, 1px);
      transition: .1s ease-in-out;
      filter: grayscale(100%);
    }
  }

  & > div {
    display: flex;

    &.s-warp {
      flex: 1;
      position: relative;
      overflow: hidden;

      & > div {
        transition: .3s linear;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        flex: 1;
        cursor: pointer;
        display: flex;
        min-width: 100%;
      }
    }
  }

  .i-all {
    flex: none;
    width: 130px;
  }

  .i-all i {
    background: url(../../assets/images/new/icon/al.png) no-repeat center !important;
  }
}

.hlb_c_mask {
  position: absolute;
  opacity: 0;
  top: 0;
  bottom: 0;
  left: 60px;
  right: 10px;
  background: #fff;
  display: flex;
  align-items: center;
  padding-right: 30px;
  transition: .1s;

  &:hover {
    opacity: 1;

    .i0 {
      &:after {
        animation: b0 .7s .3s both ease-in-out;
      }

      &:before {
        animation: b0 .4s .1s both ease-in-out;
      }
    }

    .i1 {
      &:after {
        animation: b1 .7s .3s both ease-in-out;
      }

      &:before {
        animation: b1 .3s .1s both ease-in-out;
      }
    }

    .i2 {
      &:after {
        animation: b2 .6s .4s both ease-in-out;
      }

      &:before {
        animation: b2 .4s .8s both ease-in-out;
      }
    }

    .i3 {
      &:after {
        animation: b3 .6s .3s both ease-in-out;
      }

      &:before {
        animation: b3 .4s .6s both ease-in-out;
      }
    }
  }

  i {
    display: block;
    width: 20px;
    height: 20px;
    pointer-events: none;
    position: absolute;

    &:after, &:before {
      transform: translate3d(0, 0, 0) scale(0);
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      background: #458cf9;
      border-radius: 50%;
      position: absolute;
      left: 0;
      right: 0;
    }
  }

  .i0, .i1 {
    top: 14px;
  }

  .i1, .i2 {
    right: 38px;
  }

  .i2, .i3 {
    bottom: 16px;
  }

  .i3, .i0 {
    left: 15px;
  }

  span {
    box-sizing: content-box;
    display: block;
    width: 80%;
    height: 38px;
    text-align: center;
    box-shadow: #458cf9 0 4px 14px -6px;
    margin-left: 20px;
    line-height: 38px;
    background: #458cf9;
    border-radius: 8px;
    background-size: contain;
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;

    &:hover {
      color: #fff;
      text-decoration: none;
    }
  }
}

.x-ipt {
  font-size: 12px;
  position: relative;
  display: flex;
  width: 236px;

  i {
    pointer-events: none;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 40px;
    height: 40px;
    z-index: 4;
    background: center no-repeat;
  }

  input {
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, .1);
    padding: 0 10px 0 40px;
    background: #fff;
    background-clip: border-box;
    height: 40px;
    flex: 1;
    margin: 0 !important;
  }

  span {
    position: absolute;
    left: 1px;
    right: 1px;
    top: 1px;
    bottom: 1px;
    padding: 0 10px 0 40px;
    background: #fff;
    display: flex;
    align-items: center;
    border-radius: inherit;
    pointer-events: none;
    color: #fea2aa;
    font-size: 13px;
  }

  &.pwd {
    i {
      background-image: url("./img/p.svg");
    }
  }

  &.money {
    i {
      background-image: url("./img/m.svg");
    }
  }

  &.edit {
    i {
      background-image: url("./img/e.svg");
    }
  }
}

.x-sc .sel-ipt {
  padding: 0 !important;
  width: 150px;
  display: flex;

  .x-select {
    flex: 1;
  }

  .x-select, input {
    box-shadow: none;
    width: auto !important;
    background: none;
    border: none !important;
    margin: 0 !important;
    height: inherit !important;
    min-width: 0 !important;
  }

  input {
    outline: none;
    flex: 2;
    padding: 0 5px;
  }
}

.no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px auto;


  i {
    display: block;
    background: url("./img/no0.svg") no-repeat center;
    background-size: 100% 100%;
    width: 243px;
    height: 150px;
    margin-bottom: 20px;
  }

  span {
    color: #C5C5C5;
    font-size: 14px;
  }
}

.inf-tip {
  margin-left: 4px;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #B1B1B1;
  width: 14px;
  height: 14px;
  cursor: pointer;

  &:hover {
    & > div {
      pointer-events: all;
      opacity: 1;
      z-index: 10;
    }
  }

  & > div {
    color: #666;

    &:after {
      content: '';
      border: 6px solid transparent;
      border-bottom-color: #faf1c8;
      display: block;
      position: absolute;
      top: -12px;
      left: 50%;
      transform: translateX(-50%);
    }

    pointer-events: none;
    opacity: 0;
    transition: .3s ease-in-out;
    position: absolute;
    top: 17px;
    left: 50%;
    transform: translateX(-50%);
    width: 300px;
    padding: 10px;
    background: #faf1c8;
    border-radius: 10px;
    box-shadow: 4px 3px 8px -2px rgba(136, 136, 136, 0.5);
    white-space: normal;
    font-size: 12px;
    text-align: left;
    line-height: 2;

    pre {
      white-space: normal;
    }
  }
}

.mr-data {
  position: relative;
  display: inline-flex !important;
  flex: 0;
  border-radius: 5px;
  background: #f1f1f1;
  height: 24px !important;
  color: #333 !important;
  align-items: center;
  justify-content: center;
  padding: 0 15px 0 10px;
  cursor: pointer;
  z-index: 5;

  & > .prz-num {
    padding-right: 15px;
  }

  &.nu {
    padding: 0;
  }

  &:after {
    position: absolute;
    right: 5px;
    content: '';
    display: block;
    border: 5px solid transparent;
    border-top-color: rgba(0, 0, 0, 0.1);
    border-bottom: 0;
  }

  .mr-extra {
    font-size: 13px;
    color: #666;
    z-index: 5;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    width: 140px;
    display: none;
    position: absolute;
    border: solid 1px #d9d9d9;
    background: #f8f8f8;
    border-radius: 5px;
    box-shadow: 4px 3px 8px -2px rgba(136, 136, 136, 0.5);
    padding: 10px;

    &:after {
      content: '';
      background: inherit;
      border: inherit;
      width: 8px;
      height: 8px;
      display: block;
      position: absolute;
      top: -6px;
      left: 50%;
      border-right: 0;
      border-bottom: 0;
      transform: translateX(-50%) rotateZ(45deg);
    }

    label {
      display: block;
      width: 30px;

      &:after {
        content: ':';
      }
    }

    & > div {
      margin: 4px 0;
      display: flex;
    }
  }

  &:hover {
    .mr-extra {
      display: block;
    }
  }
}

i.i-role {
  font-style: normal;
  width: 17px;
  height: 17px;
  background: #ffe7de;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #b8b8b8 !important;
  border-radius: 50%;
  margin-right: 4px;
  font-size: 12px;

  &.a {
    background: #ecf5ff;
  }
}

.sub-ban {
  overflow: hidden;
  position: relative;
  display: flex;
  background: #273151;
  height: 80px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 5px 10px -4px;

  .do {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 0 3%;

    i {
      border-radius: 50%;
      margin: 3px 0;
      display: block;
      width: 6px;
      height: 6px;
      background: #d8d8d8;
    }
  }

  .bg {
    position: absolute;
    pointer-events: none;
    z-index: 3;
    opacity: .8;
    background: url("./img/bn/r0.png") repeat-x;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
  }

  .s0 {
    z-index: 2;
    width: 1240px;
    position: relative;
    margin: 0 auto;
  }

  .abs {
    margin-right: 20px;
    background: url("./img/bn/r.svg");
    width: 316px;
    height: 57px;
  }

  .s10 {
    height: 24px;
    width: 24px;
    background: #273151;
    border-radius: 50%;
    position: relative;
    left: -12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .s11 {
    border-radius: 50%;
    width: 9px;
    height: 9px;
    border: solid 1px #559fdb;
    background: #94c8dd;
    box-shadow: 0 0 5px 1px #52b0eb;
  }

  .s01 {
    width: 1000px;
    align-items: center;
    position: absolute;
    top: 0;
    left: 20px;
    bottom: 0;
    display: flex;
  }

  .s1 {
    &:hover {
      background: linear-gradient(180deg, #56c8fa, #148de7);
    }

    cursor: pointer;
    transition: .3s ease-in-out;
    position: absolute;
    top: 0;
    left: 1100px;
    width: 1000px;
    bottom: 0;
    background: linear-gradient(180deg, #73D3FA, #3FA7F3);
    display: flex;
    align-items: center;

    span {
      margin-left: 10px;
      width: 46px;
      height: 56px;
      color: #ffffff;
      font-size: 21px;
      font-weight: 200;
      line-height: 28px;
      letter-spacing: 1.75px;
    }
  }
}

.cm-tp {
  display: flex;
  align-items: center;
  flex: 1;

  &.c-b2 {
    width: 200px;
    flex: none;
  }

  * {
    font-weight: 200;
  }

  span {
    color: #f2f2f2;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 1px;
    display: flex;
    align-items: baseline;

    b {
      align-items: baseline;
      display: flex;
      height: 32px;
      font-size: 16px;
      color: #64DCFC;
      background: -webkit-linear-gradient(#64DCFC, #34B6F7);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      span {
        color: #34B6F7;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 2px;
      }
    }
  }

  p {
    color: #b6b6b6;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1px;
  }

  .ci {
    border-radius: 50%;
    width: 54px;
    height: 54px;
    background: linear-gradient(90deg, #66DCFC, #36B7F8);
    margin-right: 8px;
    position: relative;

    & > div {
      height: 50%;
      overflow: hidden;
      width: 100%;

      div {
        transition: .5s linear;
        width: 100%;
        height: 100%;
        background: #273151;
      }
    }

    .a {
      border-radius: 23px 23px 0 0;
      transform-origin: center bottom;
    }

    .b {
      border-radius: 0 0 23px 23px;
      transform-origin: center top;
    }

    & + div {
      padding-bottom: 9px;
    }

    i {
      z-index: 5;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      display: block;
      width: 46px;
      height: 46px;
      background: #273151 no-repeat center;
      border-radius: 50%;
    }

    .b0 {
      background-image: url("./img/bn/0.png");
    }

    .b1 {
      background-image: url("./img/bn/1.png");
    }

    .b2 {
      background-image: url("./img/bn/2.png");
    }
  }
}

.video-bx {
  text-align: center;
  position: relative;
  overflow: hidden;

  video {
    pointer-events: none;
    z-index: 1;
    background: transparent;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 480px;
    height: 269px;
  }

  .ctx {
    cursor: pointer;
    width: 100%;
    height: 271px;
    border: 1px solid rgba(191, 191, 191, 0.3);
    background: #000 center no-repeat;
    background-clip: content-box;
    position: relative;

    &:hover {
      .v-bg, i {
        opacity: 1;
      }
    }

    .v-bg {
      display: none !important;
      transition: .3s ease-in-out;
      height: 100%;
      background: center no-repeat;
      background-size: 100% 100%;
      opacity: .6;
      position: relative;
    }
  }

  span {
    text-transform: uppercase;
    height: 24px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 200;
    line-height: 19px;
    letter-spacing: 1.05px;
  }
}

.unread {
  width: 16px;
  height: 16px;
  background: #799df9;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border-radius: 50%;
  position: relative;
  left: 5px;
  top: -6px;
  cursor: pointer;
  color: #fff;

  &:hover {
    transform: scale(1.1);
  }
}

.fk-l {
  font-size: 12px;
  position: relative;
  overflow: hidden;
  flex: 1;
  height: 50px;

  .f-mk {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .fg {
    top: 0;
    position: absolute;
    display: flex;
    align-items: center;

    &.a {
      transform: translateY(-100%);
    }

    &.c {
      transform: translateY(100%);
    }

    color: #eee;
    height: 50px;
    overflow: hidden;
    transition: .5s ease-in-out;
  }

  span {
    padding: 0 3px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  i {
    width: 25px;
    height: 25px;
    display: block;
    background-size: contain;
    margin-right: 3px;
  }

  &.a {
    .fg {
      transition-delay: .1s;
    }
  }

  &.b {
    .fg {
      transition-delay: .2s;
    }
  }

  &.c {
    .fg {
      transition-delay: .3s;
    }
  }
}

.c-page {
  display: flex;
  position: relative;

  iframe, .iframe {
    display: flex;
    background: #000;
    flex: 1;
    min-height: 720px;
    border: 0;
  }
}


.serv-win {
  border-bottom: 1px solid #ddd;
  position: fixed;
  top: 50%;
  overflow: hidden;
  right: -350px;
  z-index: 200;
  background: #fff;
  transition: .5s ease-out right, .3s ease-out opacity;
  height: 580px;
  width: 320px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, .3) 0 -3px 20px -5px;
  margin: -290px 0;
  opacity: 0;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  color: #ddd;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 13px;
    color: #ddd;
  }

  &.act {
    right: 20px;
    opacity: 1;
  }

  .ti {
    user-select: none;
    cursor: move;
    position: relative;
    z-index: 2;
    height: 34px;
    font-size: 13px;
    display: flex;
    align-items: center;
    background: #039be5;
    color: rgba(255, 255, 255, .7);
    padding: 0 10px;
    justify-content: space-between;

    span {
      opacity: .6;
      padding: 2px 3px;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }
  }

  iframe {
    border: 0;
    position: absolute;
    top: -129px;
    left: -20px;
    width: 356px;
    flex: 1 1;
    background: none;
    height: 755px;
  }
}

.no-card {
  font-size: 13px;
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
  }

  span {
    color: #FD7373;
    height: 24px;
    border-radius: 5px;
    background: #fce8e8;
    display: block;
    line-height: 24px;
    padding: 0 10px;
  }

  i {
    background: url("./img/e.png");
    width: 24px;
    height: 31px;
    display: block;
  }
}


.x-check {
  user-select: none;
  cursor: pointer;
  margin: 0 10px;
  display: flex;
  font-size: 13px;
  align-items: center;
  color: #aaa;

  div {
    border: 1px solid #ddd;
    font-size: 12px;
    margin-right: 5px;
    width: 20px;
    height: 20px;
    color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
  }

  &.act {
    color: #111;
    border-color: transparent;

    div {
      color: #fff;
      background: #0d9fff;
    }
  }
}

.x-user-ls {
  padding: 0 10px 0;
  margin: 10px 10px -10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background: #f2f2f2;

  span {
    &:hover {
      opacity: 1;
      background: #0d9fff;
      color: #fff;
    }

    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: .5;
    padding: 10px 20px;
    font-size: 13px;

    &:last-child {
      opacity: 1;
      background: #fff;
      color: #333;
    }
  }
}

.spn {
  width: 1280px;
  margin: 30px auto 64px;
  display: flex;
  justify-content: space-between;
  filter: drop-shadow(0px 6px 16px rgba(0, 0, 0, 0.5));
  align-items: center;

  div {
    width: 413px;
    height: 100px;
    background-size: cover;
  }
}

.spn0 {
  background-image: url("./img/spn0.png");
}

.spn1 {
  background-image: url("./img/spn1.png");
}

.spn2 {
  background-image: url("./img/spn2.png");
}

.ct-btn {
  background: #272f45;
  border-radius: 6px;
  display: flex;
  align-items: center;
  position: relative;

  .c1 {
    background: #3270e1;
    border-radius: 6px;
    border: none;
    width: 60px;
    text-align: center;
    line-height: 40px;
    height: 40px;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
  }
}
