.popup-win {
  .pw {
    padding-right: 0;
    input {
      outline: none;
      padding-left: 35px;
      &::placeholder {
        color: #aaa;
      }
      margin-bottom: 24px;
      padding-right: 45px;
      width: 100%;
      height: 35px;
      border-radius: 8px;
      border: solid 1px #ddd;
      background: #fff;

      &:focus {
        border-color: #377ef6;
        box-shadow: inset rgba(76, 157, 210, 0.4) 0 1px 4px;
      }
    }

    [type="password"] {
      background: #fff url("../pop/img/lk.png") no-repeat 13px center;
    }

    i {
      right: 5px;
    }
  }

  .pop-pw {
    &.ph {
      p{
        text-align: center;
        font-size: 13px;
        margin: 20px 0;
      }
      .pw.pfx{
        display: flex;
        align-items: center;
        &>div{
          display: flex;
          align-items: center;
          color: #fff;
          border-radius: 5px;
          margin-left: 5px;
           height: 32px;
          font-size: 13px;
          padding:  0 10px;
          background: #aaa;
         &.act{
           background: #0a94e3;
           cursor: pointer;
           box-shadow: rgba(0,0,0,.1) 0 2px 4px;
           opacity: .8;
           &:hover{
             opacity: 1;
           }
         }
        }
      }
      .i-ph {
        right: auto;
        left: 8px;
        top: 0;
        height: 35px;
        display: flex;
        align-items: center;
        font-size: 13px;
       font-style: normal;
        background: none;
        &+ input{
          margin: 0;
          flex: 1;
          padding-left: 40px;
        }
      }
    }
    & > p {
      text-align: center;
      color: #666;
      margin-top: 10px;
      font-size: 13px;
    }

    .warn {
      background: url("./img/i.png");
      width: 102px;
      height: 102px;
      margin: 10px auto;
      display: block;
    }

    .btn {
      border-radius: 4px;
      height: 35px;
      width: 115px;
      background: linear-gradient(90deg, #56b7ff, #4590fe);
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      box-shadow: rgba(55, 126, 246, 0.5) 0 8px 10px -6px;
      margin: 25px auto 5px;
      transition: .2s ease-in-out;

      &.dis {
        pointer-events: none;
        background: #aaa;
        box-shadow: rgba(100, 100, 100, 0.5) 0 8px 10px -6px;
      }

      &:hover {
        opacity: .8;
      }
    }

    .focus + label .er {
      display: none;
    }

    .r {
      display: flex;
      flex-direction: column-reverse;
      padding: 0;
    }

    .er {
      width: 100%;
      display: flex;
      align-items: center;
      color: orangered;
      height: 30px;
      font-size: 12px;
      &:before {
        content: '!';
        width: 13px;
        height: 13px;
        display: flex;
        margin: 0 3px;
        align-items: center;
        justify-content: center;
        background: orangered;
        color: #fff;
        border-radius: 50%;
      }
    }

    label {
      flex-wrap: wrap;
      min-height: 53px;
      display: flex;
      align-items: center;
      font-size: 13px;

      &:before {
        content: '*';
        color: red;
        padding: 5px 5px 0 0;
      }
    }

    padding: 25px 46px;
  }

  .tp {
    display: flex;
    align-items: center;
    background: #d0d5d7;
    padding: 0 10px;
    height: 70px;

    &.er {
      background: #efdede;
      color: red;
    }

    i {
      font-style: normal;
      width: 16px;
      height: 16px;
      border: 1px solid currentColor;
      border-radius: 50%;
      margin: 0 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ff6300;
    }

    p {
      flex: 1;
    }
  }

  .t {
    height: 50px;
    border-bottom: 1px dashed #fff;

    i {
      background: url("../account/img/bb.png");
      width: 24px;
      height: 24px;
      display: block;
      margin-right: 4px;
    }
  }

  .r {
    padding: 0 20px;
  }
}
