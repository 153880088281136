.pw {
  padding-right: 40px;
  position: relative;

  i {
    position: absolute;
    right: 0;
    top: 2px;
    display: block;
    width: 40px;
    height: 40px;
    background: url("../../assets/images/bnk/eye_.svg") no-repeat center;
    background-size: 40%;
  }

  .s {
    background-image: url("../../assets/images/bnk/eye.svg");
  }
}
