.pay-result {
  z-index: 5;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #f2f2f2;

  .tip {
    background: none !important;
  }

  .we, .ali {
    width: 305px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
      padding-bottom: 20px;
    }
  }

  .ali {
    span {
      width: 268px;
    }

    .s0 {
      background: url("./img/a9.svg");
      width: 268px;
      height: 350px;
      background-size: contain;
    }
  }

  .we {
    span {
      width: 301px;
    }

    .s0 {
      background: url("./img/w0.svg");
      width: 301px;
      height: 338px;
    }
  }

  .tip {
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      background: url("./img/e.png");
      width: 24px;
      height: 31px;
      display: block;
    }

    p {
      display: flex;
      align-items: center;
      width: 583px;
      height: 24px;
      border-radius: 5px;
      background: #fce8e8;
      justify-content: center;
      color: #fc8b8b;

      a {
        cursor: pointer;
        padding: 0 3px;
        color: red;
        text-decoration: underline;
      }
    }
  }

  .btns {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px;

    .gr {
      margin-right: 30px;
      background: #74ad7c;
      box-shadow: #74ad7c 0 8px 16px -10px;
    }
  }

  .pay-inf {
    margin: 20px auto;
    height: 147px;
    display: flex;
    border: 1px solid #e6e6e6;

    .tm {
      width: 221px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: url("./img/t.png") center no-repeat;
      font-size: 23px;
    }

    .r {
      .cp {
        font-size: 12px;
        padding: 5px 8px;
        border: 1px solid #e6e6e6;
        cursor: pointer;

        &:hover {
          background: #eee;
        }
      }

      span {
        flex: 1;
      }

      label {
        width: 240px !important;
      }

      & + .r {
        border-top: 1px dashed #e6e6e6;
      }

      width: 541px;
      height: 50px;
    }
  }

  .amt {
    margin: 20px auto;
    height: 74px;
    width: 347px;
    background: linear-gradient(#f4faff, #f4faff 12px, #e5f2ff 12px, #e5f2ff);
    background-size: 1px 13px;
    text-align: center;
    padding-top: 10px;

    span {
      color: #aaa;
    }

    div {
      color: #45a4ff;
      font-size: 30px;
      padding-top: 3px;
    }
  }
}

.pay-ctx {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;

  .fr {
    line-height: 30px;
    padding-left: 23px;
    color: #ff9800;
    background: #fffef3;
    border-bottom: 1px solid #fbf5e2;
  }

  .r {
    padding-left: 20px !important;
  }

  .otherP {
    position: absolute;
    white-space: nowrap;
    top: 17px;
    bottom: 0;
    display: flex;
    align-items: center;
    left: 108%;
    cursor: pointer;

    i {
      display: block;
      width: 16px;
      height: 16px;
      color: #fff;
      padding: 0;
      line-height: 1;
      background: #f0f0f0;
      margin-right: 5px;
      border-radius: 3px;
      box-shadow: inset #ddd 0 0 5px;
      border: 2px solid #ddd;
    }

    .act {
      &:before {
        content: '✓';
      }

      background: #0d9fff;
      border-color: #0d9fff;
      box-shadow: none;
    }
  }

  .ra {
    box-shadow: inset rgba(0, 0, 0, .15) 0 0 2px;
    font-size: 15px;
    outline: none;
    border: 1px solid #dedede;
    border-radius: 5px;
    height: 38px;
    width: 203px;
    padding: 0 15px;
    background: #f7f7f7;
    display: flex;
    align-items: center;
    color: #999;
  }

  .a {
    padding-top: 16px;
  }

  .tip {
    font-size: 12px;
    margin-left: 10px;
    color: #e37902;
    background: #ffede0;
    padding: 5px 10px;
    border-radius: 3px;

    &.gr {
      color: #008200;
      background: #efffef;
    }
  }
}

.charge-page.x-sheet {
  padding: 40px 0 40px;
  color: #666;
  font-size: 13px;
  overflow: auto;


  .btn {
    width: 300px !important;
    height: 50px !important;
    margin: 20px 200px 0 !important;
  }

  .x-select {
    width: 300px;
    height: 50px;
    border-radius: 4px;
    border: solid 1px #dedede;
    background: #ffffff;
    padding: 0 10px;
    display: flex;
    align-items: center;
    margin: 0;

    .opts {
      max-height: 160px;
    }
  }

  .dis {
    background: #aaa;
    pointer-events: none;
  }

  .r {
    padding: 10px 25px;
    display: flex;
    align-items: center;

    label {
      font-size: 16px;
      padding-left: 50px;
      width: 180px;
    }
  }

  .q {
    padding: 0px 0 20px 200px;
    display: flex;

    span {
      cursor: pointer;
      display: flex;
      align-items: center;
      height: 50px;
      min-width: 90px;
      padding: 0 15px;
      border: 1px solid #d3d3d3;
      justify-content: center;
      margin-right: 6px;
      position: relative;
      overflow: hidden;

      &:hover, &.act {
        color: #3689fa;
        border-color: #3689fa;
      }

      &.act:after, &.act:before {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
      }

      &.act:before {
        width: 30px;
        height: 30px;
        background: #3689fa;
        transform: translate3d(15px, 15px, 0) rotateZ(45deg);
      }

      &.act:after {
        content: '✓';
        color: #fff;
        right: 2px;
        font-size: 12px;
      }
    }
  }

  .sub {
    margin-top: 20px;
    margin-left: 110px;
  }

  .pm {
    margin-top: 10px;
    margin-bottom: -30px
  }

  .pt {
    height: 38px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: orange;
    border: 1px solid orange;
    border-radius: 4px;
  }

  input {
    box-shadow: none;
    font-size: 13px;
    outline: none;
    border: 1px solid #dedede;
    border-radius: 4px;
    height: 50px;
    width: 300px;
    padding: 0 10px;
  }

  .am {
    padding: 32px 20px;

    span.er {
      color: orangered;
    }

    input:not(:focus).er {
      color: orangered;
    }

    span.ok {
      color: #83c96b;
    }

    .a {
      position: relative;

      &:before {
        content: '￥';
        position: absolute;
        left: 10px;
        top: 28px;
        font-size: 15px;
        color: #999;
      }
    }

    s {
      display: block;
      height: 15px;
      width: 16px;
      border: 1px solid;
      border-top: 0;
      border-right: 0;
      position: absolute;
      left: 15px;
      top: 72px;
      opacity: .5;
    }

    .ud {
      position: absolute;
      left: 320px;
      top: 20px;
      white-space: nowrap;
      color: #76bf5d;
    }

    span {
      position: relative;
      top: 15px;
      left: 38px;
    }

    input {
      font-size: 15px;
      height: 46px;
      padding: 0 30px;

      &:focus {
        border-color: #76B555;
        color: #76B555;
        box-shadow: inset rgba(55, 246, 96, 0.4) 0 0 5px;
      }
    }

    .ok {
      &:before {
        color: #76B555;
      }

      input {
        border-color: #76B555;
        color: #76B555;
      }
    }
  }

  .way {
    padding: 0;

    .c {
      position: relative;
      border: 1px solid #eee;
      border-radius: 3px;
      height: 110px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 4px;
      overflow: hidden;
      width: 110px;
      padding-bottom: 20px;

      & + span {
        position: absolute;
        bottom: 15px;
        left: 0;
        right: 0;
      }

      b {
        display: none;
        position: absolute;
        right: 0;
        bottom: 0;

        span {
          position: relative;
          color: #fff;
          padding: 2px;
        }

        &:before {
          position: absolute;
          right: -12px;
          bottom: -10px;
          transform: rotateZ(-45deg);
          content: '';
          height: 20px;
          width: 40px;
          display: block;
          background: #3689fa;
        }
      }
    }

    i {
      display: block;
      background: url("./img/w.png");
      height: 42px;
      width: 42px;

      &.USDT {
        background-position: -214px 0;
      }

      &.alipay {
        background-position: -128px 0;
      }

      &.alipaybank {
        background-position: -85px 0;
      }

      &.wxbank {
        background-position: -171px 0;
      }
    }

    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 90px;
      height: 110px;
      margin-right: 50px;
      text-align: center;
      position: relative;
      white-space: nowrap;

      &:hover {
        .c {
          border-color: #3689fa;
        }
      }
    }

    .act {
      color: #3689fa;

      .c {
        border-color: #3689fa;

        b {
          display: block;
        }
      }
    }
  }

  .ch {
    height: 50px;

    span {
      color: #888;
      margin-right: 27px;
      cursor: pointer;
      display: flex;
      align-items: center;

      &:hover {
        color: #000;
      }
    }

    i {
      border-radius: 50%;
      margin-right: 5px;
      border: 1px solid currentColor;
      display: block;
      position: relative;
      width: 17px;
      height: 17px;

      &.act:after {
        content: '';
        display: block;
        border-radius: 50%;
        background: currentColor;
        position: absolute;
        left: 3px;
        right: 3px;
        top: 3px;
        bottom: 3px;
      }
    }
  }
}
