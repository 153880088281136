@keyframes next-hover {
  0% {
    transform: translate(0, 0);
  }
  70% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes rotateZ360 {
  0% {
    transform: rotateZ(0deg);
  }
  60% {
    transform: rotateZ(180deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
@keyframes rotateZ-360 {
  0% {
    transform: rotateZ(0deg);
  }
  60% {
    transform: rotateZ(-180deg);
  }
  100% {
    transform: rotateZ(-360deg);
  }
}
@keyframes float {
  0% {
    transform: translate3d(0,0,0);
    opacity: 0;
  }
  20%{
    opacity: .3;
    transform: translate3d(4px,-4px,0);
  }
  50% {
    opacity: 1;
    transform: translate3d(1px,1px,0);
  }
  80%{
    opacity: .4;
    transform: translate3d(-6px,5px,0);
  }
  100% {
    opacity: 0;
    transform: translate3d(0,0,0);
  }
}
