.msgPage {
  min-height: 760px;
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #f0f1f7;
  .x-pg{
    padding:  10px  20px 0;
  }
.fs{
  padding-right: 10px;
  align-items: center;
 display: flex;
}
  tr.act {
    background: rgba(2, 124, 217, 0.1);
  }

  thead {
    display: none;
  }

  .detail {
    width: 50%;
    background: #f7f7f7;
    align-self: stretch;
    padding: 10px;

    & > div {
      display: flex;
      margin: 5px 0;
      min-height: 30px;
    }

    label {
      width: 80px;
      display: block;
      font-weight: 600;
    }

    .da-c {
      border-top: 1px solid #d9d9d9;
      padding: 20px 0;
      flex: 1;
      overflow: auto;
    }
  }

  .rb {
    display: flex;
    flex: 1;
    flex-direction: column;
    tr:nth-child(2n):not(:hover){
       background: #f9f9f9;
    }
  }

  .so {
    display: flex;
  }

  .ss {
    flex: 1;

    &.sa {
      display: flex;
      flex-direction: column;
      background: #fff;
      padding-bottom: 10px;
    }
  }

  th {
    height: 20px;
    font-size: 12px;
    color: #ccc;
    font-weight: 400;
  }
  th,td{
    text-align: left;
  }

  th:first-child,
  td:first-child {
    width: 60px;
    padding-left: 20px;
  }
  th:last-child,
  td:last-child {
    width: 200px;
  }

  .box-opt {
    display: flex;
    padding: 0 20px;
    align-items: center;
    height: 48px;
    background: #f9f9f9;
    border-bottom: 1px solid #f3f3f3;

    label {
      margin-right: 10px;
    }

    b {
      color: #0A94E3;
    }

    .del, .clear {
      width: 58px;
      height: 35px;
      text-align: center;
      line-height: 35px;
      cursor: pointer;
      border-radius: 3px;
      color: #fff;
      margin-right: 10px;
      background: linear-gradient(90deg, #df7a33, #f07329);

      &:hover {
        opacity: .9;
      }
    }

    .clear {
      background: linear-gradient(90deg, #5194f8, #5294ff);
    }
  }

  .del, .clear, td,.fs {
    .sel {
      display: block;
      width: 24px;
      height: 24px;
      cursor: pointer;
      border-radius: 50%;
      border: 1px solid #dcdcdc;

      &.act {
        background: #0a94e3;
        box-shadow: inset 0 0 1px 4px #fff;
      }
    }
  }


  .box-ft {
    height: 64px;
    display: flex;
    align-items: center;
    background: #fff;
    padding: 0 20px;

    input {
      width: 250px;
      margin: 0 10px 0 0;
      outline: none;
    }

    .b {
      background: url("../account/img/b.png");
      width: 14px;
      height: 14px;
    }

    .btn {
      height: 40px;
      width: 100px;
      padding-right: 16px;
      justify-content: space-around;

      i {
        background: #fff url("./s.svg") no-repeat center;
        background-size: 50%;
        width: 25px;
        height: 25px;
        border-radius: 50%;
      }
    }
  }

  .box-m {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .u {
    text-align: right;
    margin:  10px 0;
    b {
      padding: 0 4px;
      color: #0d9fff;
      font-weight: 200;
    }
  }

  .tt {
    padding: 0 10px;
    display: flex;
    background: #f4f7fa;
    input {
      background: none;
      outline: none;
      border: none;
      text-align: right;
      flex: 1;
      margin: 0 0 0 10px;
    }
  }

  input {
    box-shadow: none;
    margin-left: 10px;
    border: none;
    height: 33px;
    min-height: 0 !important;
    line-height: 33px;
    padding: 0 10px;

    &::placeholder {
      color: #ccc;
    }
  }

  .x-select {
    height: 40px;
    border: none;
    align-items: center;
    display: flex;
    background: none;

    .opts {
      div {
        height: 40px;
        line-height: 40px;

        &.s, &:hover {
          background: #2b81ff;
          color: #fff;
        }
      }
    }
  }

  .rr {
    flex-direction: column;

    input {
      margin: 0 10px;
      flex: 1;
    }
  }

  .ls {
    display: flex;
    flex: 1;
    flex-wrap: wrap;

    span {
      border-radius: 3px;
      background: #dce7ff;
      padding: 0 0 0 6px;
      height: 24px;
      align-items: center;
      display: flex;
      margin: 2px;
      cursor: pointer;

      &:hover {
        box-shadow: #4f8fd8 0 4px 10px -5px;
      }

      i {
        font-style: normal;
        width: 20px;
        margin-left: 5px;
        height: inherit;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background: #d8001f;
          color: #fff;
        }
      }
    }
  }

  .rw, .ra, .rr, .ry, .wr {
    flex: 1;
    display: flex;
  }

  .dash-title {
    background: #fff;
    position: relative;
  }

  .t {
    background: #f7f7f7;
    display: flex;

    div {
      height: 45px;
      width: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: .2s ease-in-out;
      cursor: pointer;

      &:hover {
        opacity: .9;
      }
    }
    .act{
      font-weight: 600;
    }
    .act, div:hover {
      background: #fff;
      color: #000;
    }
  }

  .als {
    flex: 1;
    position: relative;
   &>div{
     overflow: auto;
     max-height: 350px;
   }
  }

  textarea {
    overflow: auto;
    padding: 10px;
    line-height: 1.4;
  }

  .ra {
    display: flex;

    .rc {
      display: flex;
      flex-direction: column;
      margin-right: 10px;

      .s {
        color: #666;
      }

      .ur, span {
        padding: 0 10px;
      }
      .tt,.s,.ur {
        min-height: 40px;
        line-height: 40px;
      }

      .ur {
        transition: .1s ease-in-out;
        display: flex;
        align-items: center;
        cursor: pointer;

        label {
          flex: 1;
        }

        b {
          width: 12px;
          height: 12px;
          min-height: 0;
          display: block;
          border-radius: 50%;
          border: 1px solid #a6a6a6;

          &.act {
            background: #2883ff;
          }
        }

        &:nth-child(2n-1) {
          background: #f9f9f9;
        }

        &:hover {
          color: #fff;
          background: #2883ff;

          b {
            border-color: #fff;

            &.act {
              background: #fff;
            }
          }
        }

        i {
          min-height: 0;
          display: block;
          width: 25px;
          height: 25px;
          margin-right: 10px;
          border: 1px solid #fff;
          border-radius: 50%;
          background: #0a94e3 url("../../assets/images/bnk/ur.svg") no-repeat center;
          background-size: 50%;
        }
      }
    }

    .rx {
      margin-bottom: 10px;
      height: 48px;
      align-items: center;
      display: flex;
      padding-left: 20px;
      input{
        outline: none;
        border: none;
      }
    }

    .rc, .rx, .ry {
      border: #f2f2f2 1px solid;
      border-radius: 5px;
      outline: none;
    }

    .rc {
      width: 278px;

    }
  }

  .re {
    display: flex;
    min-height: 60px;
    align-items: center;
  }

  .wr {
    border-radius: 0 0 10px 10px;
    background: #fff;
    flex: 1;
    flex-direction: column;
    padding: 0 10px 30px;
  }

  .ff {
    right: 10px;
    top: 90px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    color: #aaa;

    p {
      text-align: center;
      flex: 1;
    }
  }

  .btn {
    margin-left: 50px;
    transition: .2s ease-in-out;
    &:hover {
      opacity: .9;
    }
    i {
      width: 20px;
      height: 20px;
      display: block;
      background: url("../../assets/images/bnk/send.svg") center no-repeat;
      background-size: 100%;
      margin-right: 4px;
    }
    cursor: pointer;
    padding: 5px;
    height: 38px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #2b44b1;
    border-radius: 5px;
  }
}
