.s-inf {
  margin: 0 auto;
  table{
    font-size: 12px;
    width: 100%;
    margin:  5px auto;
    background: #fff;
    th{
      font-weight: 200;
      color: #999;
    }
    th,td{
      padding: 6px;
    }
  }
  p{
    margin: 10px 0;
    span{
      padding:  0 20px;
    }
  }
  .e{
    border-bottom: 1px dashed #ddd;
    padding-bottom: 20px;
  }
  .r{
    width: 90%;
    margin: 17px auto 17px;
    display: flex;
    justify-content: space-between;
    div{
      width: 140px;
      display: flex;
      text-align: left;
      span{
        flex: 1;
        text-align: right;
      }
    }
  }
}

.lk-dt-msk {
  z-index: 11;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.16);
  display: none;
  align-items: center;
  justify-content: center;
  transition: .3s ease-in-out;

  &.a, &.b {
    display: flex;
  }

  &.a.b {
    opacity: 1;
  }

  .btn {
    background: #2a82ff;
    cursor: pointer;
    color: #fff;
    display: flex;
    height: 45px;
    align-items: center;
    justify-content: center;

    &:hover {
      opacity: .9;
    }
  }

  .lk-pm {
    width: 365px;
    position: relative;
    display: flex;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.82);
    backdrop-filter: blur(10px);

    .lt {
      display: flex;
      align-items: center;
      padding: 0 20px;
      height: 45px;
    }

    .lks {
      position: relative;
    }

    .lss {
      min-height: 200px;
      max-height: 400px;
      overflow: auto;
      .po{
        display: flex;
        height: 40px;
        align-items: center;
        justify-content: space-between;
        padding:  0 30px;
        font-size: 13px;
        span+span{
          color: #999;
        }
        &:nth-child(2n+1){
          background: rgba(0,0,0,.02);
        }
      }
    }

    i {
      background: url("./img/bb.png");
      width: 24px;
      height: 24px;
      margin-right: 5px;
      border-radius: 50%;
    }
  }
}

.accPage {
  display: flex;
  flex-direction: column;

  .shr {
    display: flex;
    flex-direction: column;
    flex: 1;
    background: #f5f8fa;
    margin: -10px;
    padding: 10px;

    .c21 {
      background: #f5f8fa;
    }
  }

  .sub-c {
    background: #fff;
    padding: 20px 10px 10px;
    display: flex;
    justify-content: flex-end;
    margin: -10px;
  }

  .link-his, .q {
    flex: 1;
  }

  .link-his {
    padding-bottom: 10px;
    background: #fff;
    flex: 1;
    display: flex;
    flex-direction: column;

    tr {
      td {
        border-bottom: 1px dashed #eee;
      }
    }
  }

  .fl {
    flex: 1;
  }

  .q {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;

    .i {
      background: url("./img/d0.png");
      width: 33px;
      height: 34px;
      display: block;
      margin: 0 auto;
    }

    .opt {
      div {
        cursor: pointer;
        margin: 0 3px;
        color: #0d9fff!important;
        text-decoration: underline;
      }
    }

    table {
      .cpl {
        s {
          display: block;
          width: 28px;
          height: 28px;
          border-radius: 3px;
          margin-right: 5px;
        }


        .cp {
          box-shadow: rgba(43, 130, 255, 0.6) 0 4px 14px -4px;
          background: #2b82ff;
          color: #fff;
          width: 50px;
          height: 26px;
          line-height: 26px;
          border-radius: 4px;
        }

        s {
          background: url("./img/lk.png") no-repeat center;
        }
      }

      a {
        display: flex;
        align-items: center;
        color: #87afff;

        span {
          overflow: hidden;
          width: 160px;
          margin: 0 5px;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      tbody {
        tr {
          &:hover {
            .opt {
              div {
                color: #fff;
              }
            }
          }

          .cpl {
            .cp {
              cursor: pointer;
            }
          }
        }
      }
    }
  }


  .q0 {
    background: #fff;
    margin-bottom: 10px;
  }

  .btn {
    margin:  0 auto 5px;
    border-radius: 5px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #fff;
    background: #2b44b1;
    width: 300px;

    &:hover {
      opacity: .9;
    }
  }

  .c0 {
    flex-wrap: wrap;
    display: flex;
    margin-bottom: 10px;

    &.cr {
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .c1 {
        border: 1px solid #f4f4f4;
        width: 100%;
        height: 140px;
        align-items: center;

        .c21 {
          background: none;
          width: 400px;
        }
      }

      .rate-cfg {
        margin: 20px auto 0;
        width: 60%;
      }
    }
  }

  .c6 {
    display: flex;
    background: #fff;
    width: 100%;
    border-bottom: 1px solid #f2f2f2;

    .c21 div {
      border: 0
    }
  }

  .c1 {
    display: flex;
    flex-direction: column;
    background: #fff;
    width: 362px;
    margin-right: 10px;
    position: relative;

    .err {
      input {
        border-color: red;
      }
    }

    & > div > div {
      position: relative;

      span {
        pointer-events: none;
        position: absolute;
        background: #fff;
        color: red;
        left: 100px;
        height: 30px;
        display: flex;
        align-items: center;
        width: 220px;
      }
    }

    p {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      color: #2b81ff;
      line-height: 30px;
      text-align: center;
    }
  }


  .c {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 10px;
  }


  .c3 {
    padding: 40px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    flex: 1;

    .ipt-a {
      padding: 1px 0;
      height: 44px;
      .ld {
        background: url("./img/r.svg") no-repeat center;
        background-size: 100% 100%;
        width: 16px;
        height: 16px;
        display: block;
        position: absolute;
        right: 10px;
        top: 50%;
        opacity: .2;
        transform: translateY(-50%);
        cursor: pointer;
        &:hover{
          opacity: .5;
        }
      }
      & + .ipt-a {
        margin-top: 34px;
      }

      box-shadow: inset rgba(0, 0, 0, 0.1) 0 0 10px;
      border-radius: 3px;
      border: 1px solid #98BFFA;
      width: 80%;
      display: flex;
      position: relative;

      p {
        position: absolute;
        background: #FFF3F3;
        border-radius: 10px;
        top: -30px;
        height: 20px;
        left: 0;
        padding: 0;
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #000;

        b {
          color: #DE6438;
          width: 16px;
          height: 16px;
          line-height: 16px;
          text-align: center;
          background: #FAD6C1;
          border-radius: 50%;
          margin-right: 10px;
        }
      }

      label {
        width: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #98BFFA;
        border-radius: inherit;
        background: rgba(211, 230, 255, 0.2);

        i {
          width: 27px;
          height: 27px;
          display: block;
          border-radius: 50%;
          background: #2b82ff url("./img/usr.png") no-repeat center 110%;
          background-size: 60% auto;

          &.pwd {
            background-image: url("./img/pwd.png");
          }
        }
      }
    }

    .pw {
      padding: 0;
     flex:1;
      display: flex;
      input {
        padding-right: 50px;
      }
    }

    input {
      flex: 1;
      background: none;
      padding: 0 20px;
      border: none;
      margin: 0;
    }
  }

  .a {
    border-bottom: 1px solid #f3f3f3;
    padding: 10px;
  }

  .a, .q0 {
    display: flex;
    background: #fff;

    div {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      padding: 0 10px;
      margin: 0 20px;

      &:hover {
        color: #2a82ff;
      }
    }

    .act {
      border-bottom: 1px solid #6399E9;
      color: #2a82ff;
    }
  }
  .c2 {
    height: 98px;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .c21 {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    flex: 1;

    div {
      border: 1px solid #dee2e8;
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 10px 20px;
      height: 80px;
      width: 40%;
      border-radius: 5px;

      &:before {
        content: '';
        width: 17px;
        height: 17px;
        display: block;
        background: #b9b9b9 url("./img/ur_.png") no-repeat center;
        margin-right: 5px;
        border-radius: 50%;
      }

      &.act {
        border-color: #98BFFA;
        background: #F3F3FF;
        color: #377ef6;
        position: relative;

        &:after {
          width: 20px;
          height: 16px;
          font-size: 11px;
          right: -3px;
          top: -5px;
          border-radius: 4px;
          position: absolute;
          content: '√';
          color: #fff;
          background: #7aaffa;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &:before {
          background-color: #98BFFA;
        }
      }

      & + div:before {
        background-image: url("./img/ag_.png");
      }
    }

    .act:before {
      background: #fff url("./img/ur.png") no-repeat center;
    }
  }
}

.confirm-msk {
  transition: .3s ease-in-out;
  z-index: 20;
  background: rgba(0, 0, 0, 0.2);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  opacity: 0;
  position: fixed;

  &.a, &.b {
    display: block;
  }

  &.a.b {
    opacity: 1;
  }

  .cfm {
    font-size: 13px;
    overflow: hidden;
    width: 360px;
    background: rgba(255, 255, 255, 0.7);
    margin: 0 auto;
    top: 50%;
    position: relative;
    transform: translateY(-50%);
    backdrop-filter: blur(20px);
    box-shadow: rgba(10, 10, 10, 0.4) 0 10px 30px -12px;

    label {
      width: 100px;
      font-weight: bold;
    }

    i {
      background: url("./img/bb.png");
      height: 24px;
      width: 24px;
      margin-right: 5px;
      display: block;
    }

    .r {
      padding: 0 27px;
      height: 50px;
    }

    .rr {
      border-bottom: 1px dashed #fff;
    }

    .btn {
      justify-content: center;
      border: 0 !important;
      margin-top: 10px;
      background: #2b82ff;
      color: #fff;
      cursor: pointer;

      &:hover {
        opacity: .9;
      }
    }
  }
}
