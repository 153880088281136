.w-ico-1 {
  background-image: url('./img/pt.png')
}

.w-ico-2 {
  background-image: url('./img/ag.png')
}

.w-ico-3 {
  background-image: url('./img/mg.png')
}

.w-ico-29 {
  background-image: url('./img/pg.png')
}

.w-ico-4 {
  background-image: url('./img/gg.png')
}

.w-ico-5 {
  background-image: url('./img/bbin.png')
}

.w-ico-6 {
  background-image: url('./img/ttg.png')
}

.w-ico-7 {
  background-image: url('./img/ea.png')
}

.w-ico-8 {
  background-image: url('./img/png.png')
}

.w-ico-9 {
  background-image: url('./img/hb.png')
}

.w-ico-10 {
  background-image: url('./img/isb.png')
}

.w-ico-11 {
  background-image: url('./img/cq9.png')
}

.w-ico-12 {
  background-image: url('./img/ae.png')
}

//.w-ico-13 {background-image:url('./img/lotus.png')}
.w-ico-14 {
  background-image: url('./img/ibc.png')
}

.w-ico-15 {
  background-image: url('./img/ky.png')
}

.w-ico-16 {
  background-image: url('./img/av.png')
}

.w-ico-17 {
  background-image: url('./img/sexy.png')
}

.w-ico-18 {
  background-image: url('./img/188.png')
}

.w-ico-19, .w-ico-30 {
  background-image: url('./img/bt-12.png')
}

.w-ico-20 {
  background-image: url('./img/eg.png')
}

.w-ico-25 {
  background-image: url('./img/jdb.png')
}

.w-ico-21 {
  background-image: url('./img/bt.png')
}

.w-ico-22 {
  background-image: url('./img/yb.png')
}

.w-ico-23 {
  background-image: url('./img/yb2.png')
}

.w-ico-28, .w-ico-24 {
  background-image: url('./img/im.png')
}

.w-ico-27 {
  background-image: url('./img/lh.png')
}

.w-ico-26 {
  background-image: url('./img/ob.png')
}

.wallet-ls {
  background: #F2F2F2;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  flex-wrap: wrap;
  display: flex;
  overflow: auto;
  padding: 30px ;

  &.sm {
    margin-top: 30px;

    .wa {
      margin: 0 3px 15px 0;

      .c {
        span {
          color: #0d9fff;
        }
      }
    }
  }

  .wa {
    height: 82px;
    background-clip: border-box;
    display: flex;
    flex-direction: column;
    transition: .15s ease-in-out;
    position: relative;
    width: 176px;
    border-radius: 4px;
    border: 1px solid #d2d6de;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 5px 15px -7px;
    margin: 0 20px 10px 0;
    background: #f7f7f7;

    label {
      white-space: nowrap;
      font-size: 14px;
    }

    &:hover {
      .l {
        opacity: .7 !important;
      }
    }

    .l {
      opacity: 0;
      position: absolute;
      right: 5px;
      top: 5px;
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-position: center;
      z-index: 3;
    }

    i[class^="w-ico-"] {
      width: 56px;
      height: 56px;
      margin-right: 5px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }


    &.sm {
      .l {
        opacity: .4;
        bottom: 4px;
        top: auto;
      }

      i[class^="w-ico-"] {
        width: 40px;
        height: 40px;
      }

      label {
        font-size: 14px;
      }

      .b {
        font-size: 13px;
      }
    }

    &.in, &.ou {
      box-shadow: rgba(0, 107, 252, 0.4) 1px 2px 20px -6px;
    }

    &.in .b .e, &.ou .b .g {
      font-weight: bolder;
      opacity: 1;
      color: #fff;
      background: linear-gradient(80deg, rgba(138, 183, 255, .95), rgba(66, 144, 255, .95));
    }

    &:hover {
      box-shadow: rgba(0, 107, 252, 0.3) 1px 8px 20px -6px;
    }
  }

  .c {
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;
    padding: 0 5px;
    position: relative;

    label, span {
      font-size: 13px;
      display: block;
      line-height: 2;
    }

    span {
      b {
        font-weight: 400;
      }
    }
  }

  .a {
    background: #fff;
    border-radius: inherit;
    overflow: hidden;
    position: relative;
    display: flex;
    flex: 1;
    align-items: center;
    padding: 5px 10px;
    box-shadow: rgba(0, 0, 0, .03) 0 1px 2px;
  }

  .f {
    padding: 0 10px;
    color: #999;
    box-shadow: rgba(0, 0, 0, .03) 0 1px 2px;
  }

  .b {
    opacity: 0;
    transition: .3s ease-in-out;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;

    div {
      opacity: 1;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      background: linear-gradient(#0589fc, #6acefd);

      &:hover {
        background: linear-gradient(#05c0fc, #02ebfd);
      }

      transition: .1s ease-in-out;
      flex: 1;

      &:hover {
        opacity: 1;
      }

      border-top: 1px solid rgba(0, 0, 0, 0.1);

      & + div {
        border-left: 1px solid rgba(0, 0, 0, 0.1);
      }

      + div {
        border-left: 1px solid rgba(255, 255, 255, 0.4);
      }
    }

    .dis {
      pointer-events: none;
      opacity: 1;
      background: linear-gradient(207deg, #999, #ccc);
    }
  }

  .b:hover {
    opacity: 1;
  }

}

.wallets-page .l,
.wallet-ls .l {
  cursor: pointer;
  display: block;
  opacity: .4;
  background: url("../../../assets/images/new/r.png");
  width: 14px;
  height: 12px;
  transition: ease-in-out .5s;

  &:hover {
    opacity: 1;
    transform: rotateZ(360deg);
  }

  &.act {
    pointer-events: none;
    animation: .5s rotateZ360 both linear infinite;
  }
}

.wallets-page {
  flex-direction: row !important;

  .btn {
    margin: 50px 0!important;
    height: 50px!important;
    width: 240px!important;
  }

  .dash-title {
    h1 {
      font-size: 16px;
    }

    span {
      font-size: 12px;
      color: #999;
    }
  }

  .m-tp {
   top: 8px;
    right: 20px;
    left: 20px;
    display: flex;
    font-size: 12px;
    position: absolute;
    color: #133364;
    height: 24px;
    border-radius: 5px;
    background: #eef0f8;
    align-items: center;
    justify-content: center;
    padding: 0 18px;
  }

  .x-st-h {
    padding: 10px 20px 0;
    display: block;
    width: 301px;
    border-right: 1px solid #eee;
  }

  i {
    display: block;
  }

  .opt {
    margin-top: 20px;
    margin-bottom: 20px;

    .sw {
      display: none;
      height: 42px;
      width: 247px;
      border-radius: 30px;
      background: #ebebeb;
      //display: flex;
      position: relative;
      align-items: center;
      color: #666;
      justify-content: space-around;

      span {
        transition: .3s ease-in-out;
        position: relative;
        cursor: pointer;
      }

      .a {
        color: #fff
      }

      &.act {
        .a {
          color: inherit
        }

        .b {
          color: #fff
        }

        i {
          transform: translateX(100%);
        }
      }

      i {
        transition: .3s ease-in-out;
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        border-radius: 30px;
        width: 50%;
        box-shadow: #3078f7 0 2px 8px -3px;
        background: linear-gradient(90deg, #62a8f9, #3078f7);
      }
    }

    .g {
      padding-left: 5px;
      flex-wrap: wrap;
      height: 30px;
      align-items: center;
      display: flex;
      i{
        margin:  0 3px;
      }
    }

    & > div {
      font-size: 14px;
    }

    .h {
      justify-content: space-between;
    }

    .gr {
      margin-top: 20px;
      border-top: solid 1px #f3f3f3;
      padding-top: 10px;
      position: relative;

      input {
        outline: none;

        &:focus {
          box-shadow: #377ef6 0 0 5px;
        }
      }

      .l {
        margin-right: 20px;
      }

      margin-bottom: 15px;

      label + span {
        color: #3078f7;
        padding-right: 10px;
      }

      label {
        width: 100%;
        margin-bottom: 20px;

        span {
          display: block;
          padding: 5px;
        }
      }

      .swap {
        background: url(../../../assets/images/new/28.png);
        background-size: contain;
        width: 20px;
        height: 20px;
        transition: .2s ease-in-out;
        margin: 0 10px;
        cursor: pointer;
        position: absolute;
        right: 20px;
        top: 120px;

        &:hover {
          transform: rotateZ(180deg);
        }
      }

      .x-select, input {
        margin:10px 5px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 240px;
        height: 50px;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, .1);
      }
    }
  }
}
