@font-face {
    font-family: 'xDigital';
    src: url('subset-Digital-7.woff2') format('woff2'),
    url('subset-Digital-7.woff') format('woff'),
    url('subset-Digital-7.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
.digital{
    font-family: 'xDigital',  serif;
}
.digital *{
    font-family: 'xDigital',  serif;
}

