@import "../../styles/util";
.hid-img{
  display: none;
}
.hd-banner{
  position: relative;
  box-sizing: content-box;
  display: flex;
  align-items: center;
  height: 0;
  justify-content: flex-end;
  transition: .5s ease-in-out;
  &.act{
    padding-top: 24%;
  }
  &.sm{
    padding-top: 20%;
    border-radius: 20px 20px 0 0;
    overflow: hidden;
  }
  .carousel{
    position: absolute !important;
    left:0;
    right:0;
    top: 0;
    bottom: 0;
  }
}
